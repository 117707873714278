import Norrie from './Norrie';
import Piermont from './Piermont';
import Beczak from './Beczak';
import Marist from './Marist';
import Albany from './Albany';
import Pier84 from './Pier84';
import WestPoint from './WestPoint';

export default {
  Albany,
  Beczak,
  Marist,
  Norrie,
  Pier84,
  Piermont,
  WestPoint
};
