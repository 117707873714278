// Date/Time,Depth,,,Dissolved Oxygen,,,Dissolved Oxygen Percent,,,
// Acidity,,,Salinity,,,Specific Conductance,,,Turbidity,,,Water Temperature,,
export const params = {
  ABSP: {
    decimals: 1,
    param_code: 'ABSP',
    param_nm: 'Absolute Pressure',
    param_type: 'hyd',
    unit: 'psia',
  },
  ATMP: {
    conversion: unit => unit * 1.8 + 32,
    decimals: 1,
    param_code: 'ATMP',
    param_nm: 'Air Temperature',
    param_type: 'met',
    unit: '°F',
    usgs_code: '00020',
  },
  BARO: {
    decimals: 1,
    param_code: 'BARO',
    param_nm: 'Barometric Pressure',
    param_type: 'met',
    unit: 'Millibars',
    usgs_code: '75969',
  },
  CHL: {
    decimals: 2,
    param_nm: 'Chlorophyll',
    param_type: 'hyd',
    unit: 'RFU',
    usgs_code: '32315',
  },
  CHLA: {
    decimals: 1,
    param_code: 'CHLA',
    param_nm: 'Chlorophyll',
    param_type: 'hyd',
    unit: 'ug/L',
  },
  COND: {
    decimals: 0,
    param_code: 'COND',
    param_nm: 'Conductivity',
    param_type: 'hyd',
    unit: 'µS/cm',
  },
  DEPTH: {
    conversion: unit => unit * 0.3048,
    decimals: 2,
    nerrs_code: 'Depth',
    param_code: 'DEPTH',
    param_nm: 'Water Level',
    param_type: 'hyd',
    unit: 'meters',
    usgs_code: '62620',
  },
  DEWP: {
    conversion: unit => unit * 1.8 + 32,
    decimals: 1,
    param_code: 'DEWP',
    param_nm: 'Dew Point',
    param_type: 'met',
    unit: '°F',
  },
  DO: {
    decimals: 2,
    nerrs_code: 'DO_mgl',
    param_code: 'DO',
    param_nm: 'Dissolved Oxygen',
    param_type: 'hyd',
    unit: 'mg/L',
    usgs_code: '00300',
  },
  DOPC: {
    decimals: 1,
    param_code: 'DOPC',
    param_nm: 'Dissolved Oxygen % Air Saturation',
    param_type: 'hyd',
    unit: '%',
    usgs_code: '00301',
  },
  ELEV: {
    conversion: unit => unit * 0.3048,
    decimals: 1,
    param_code: 'ELEV',
    param_nm: 'Water Surface Elevation (relative to NAVD88)',
    param_type: 'hyd',
    unit: 'Meters',
    usgs_code: '62620',
  },
  FPC: {
    decimals: 2,
    param_nm: 'Phycocyanin',
    param_type: 'hyd',
    unit: 'RFU',
    usgs_code: '32315',
  },
  GST: {
    decimals: 1,
    param_code: 'GST',
    param_nm: 'Wind Gust (15-minute max.)',
    param_type: 'met',
    unit: 'm/s',
  },
  PAR: {
    decimals: 1,
    param_code: 'PAR',
    param_nm: 'Photosynthetically Active Radiation (PAR)',
    param_type: 'met',
    unit: 'mmoles/m^2',
  },
  PH: {
    decimals: 1,
    nerrs_code: 'pH',
    param_code: 'PH',
    param_nm: 'Acidity',
    param_type: 'hyd',
    unit: 'pH',
    usgs_code: '00400',
  },
  PWL: {
    decimals: 2,
    param_code: 'PWL',
    param_nm: 'Pumping Water Level',
    param_type: 'hyd',
    unit: 'Meters',
  },
  RAD: {
    decimals: 1,
    param_code: 'RAD',
    param_nm: 'Total Radiation',
    param_type: 'met',
    unit: 'Watts/m^2',
  },
  RAIN: {
    decimals: 1,
    param_code: 'RAIN',
    param_nm: 'Rainfall',
    param_type: 'met',
    unit: 'mm',
    usgs_code: '00045',
  },
  RAINDC: {
    decimals: 1,
    param_code: 'RAINDC',
    param_nm: 'Rainfall Daily Accumulation',
    param_type: 'met',
    unit: 'mm',
  },
  RHUM: {
    decimals: 1,
    param_code: 'RHUM',
    param_nm: 'Relative Humidity',
    param_type: 'met',
    unit: '%',
  },
  SALT: {
    decimals: 1,
    param_code: 'SALT',
    param_nm: 'Salinity',
    param_type: 'hyd',
    unit: 'ppt',
    usgs_code: '90860',
  },
  SPCO: {
    decimals: 0,
    nerrs_code: 'SpCond',
    param_code: 'SPCO',
    param_nm: 'Conductivity',
    param_type: 'hyd',
    unit: 'µS/cm',
    usgs_code: '00095',
  },
  STMP: {
    conversion: unit => unit * 1.8 + 32,
    decimals: 1,
    param_code: 'STMP',
    param_nm: 'Soil Temperature',
    param_type: 'met',
    unit: '°F',
  },
  TURB: {
    decimals: 1,
    nerrs_code: 'Turb',
    param_code: 'TURB',
    param_nm: 'Turbidity',
    param_type: 'hyd',
    unit: 'FNU',
    usgs_code: '63680',
  },
  WD: {
    decimals: 1,
    param_code: 'WD',
    param_nm: 'Wind Direction',
    param_type: 'met',
    unit: 'Degrees',
  },
  WDEV: {
    decimals: 1,
    param_code: 'WDEV',
    param_nm: 'Wind Direction Std. Dev.',
    param_type: 'met',
    unit: 'Degrees',
  },
  WEATHER: {
    decimals: 1,
    param_code: 'WSPD',
    param_nm: 'Weather',
    param_type: 'met',
    unit: 'm/s',
    usgs_code: '82127',
  },
  WSPD: {
    decimals: 1,
    param_code: 'WSPD',
    param_nm: 'Wind Speed',
    param_type: 'met',
    unit: 'm/s',
    usgs_code: '82127',
  },
  WTMP: {
    conversion: unit => unit * 1.8 + 32,
    decimals: 2,
    nerrs_code: 'Temp',
    param_code: 'WTMP',
    param_nm: 'Water Temperature',
    param_type: 'hyd',
    unit: '°F',
    usgs_code: '00010',
  },
};

export default params;
